import React from "react"

import "./Content.scss"
import { graphql, useStaticQuery } from "gatsby"
import GymSticker from "../../App/GymSticker/GymSticker"

const Content = ({ block, location }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          postAddress
        }
      }
    }
  `)
  if (block === "shipping") {
    return (
      <div>
        <h2>
          <strong>
            С 01.01.2020 для приобретения карты AllSports обратитесь к вашему
            работодателю.
          </strong>
          . Информация доступна только для ознакомления.
        </h2>
        <h3>Оформление заказа</h3>
        <p>
          Фактом оформления заказа на Клубную карту будет являться ее оплата за
          первый отчетный месяц.
        </p>
        <h3>Условия оплаты</h3>
        <h4>
          Через <strong>систему "Расчет" (ЕРИП)</strong>
        </h4>
        <figure className="wp-block-image">
          <img
            src="https://www.allsports.by/wp-content/uploads/2019/10/image.png"
            alt=""
            className="wp-image-533"
          />
        </figure>
        <p>Оплатить выбранные товары вы можете следующими способами:</p>
        <p>
          {" "}
          О платить <strong>заказ </strong>Вы можете через систему "Расчет"
          (ЕРИП), в любом удобном для Вас месте, в удобное для Вас время, в
          удобном для Вас пункте банковского обслуживания
        </p>
        <ul>
          <li>интернет-банке,</li>
        </ul>
        <ul>
          <li>с помощью мобильного банкинга,</li>
          <li> инфокиоске,</li>
          <li> кассе банков,</li>
          <li> банкомате и т.д.</li>
        </ul>
        <p>
          {" "}
          Совершить оплату можно с использованием наличных денежных средств,
          электронных денег и банковских платежных карточек, в пунктах
          банковского обслуживания&nbsp;банков, которые оказывают услуги по
          приему платежей, а также посредством инструментов дистанционного
          банковского обслуживания.<strong> </strong>
        </p>
        <p>ДЛЯ ПРОВЕДЕНИЯ ПЛАТЕЖА НЕОБХОДИМО:</p>
        <ol>
          <li>
            {" "}
            Выбрать{" "}
            <ul>
              <li>
                Пункт <strong>“Система “Расчет” (ЕРИП) </strong>
              </li>
              <li>
                <strong>Интернет-магазины/сервисы </strong>
              </li>
              <li>
                <strong>A-Z Латинские домены</strong>
              </li>
              <li>
                <strong>А </strong>
              </li>
              <li>
                <strong>Аllsports.by</strong>
              </li>
            </ul>
          </li>
          <li>
            {" "}
            Для оплаты ввести{" "}
            <em>
              <strong>Номер заказа</strong>
            </em>
          </li>
          <li> Проверить корректность информации</li>
          <li> Совершить платеж. &nbsp; </li>
        </ol>
        <p>
          <strong>
            Если Вы осуществляете платеж в кассе банка, пожалуйста, сообщите
            кассиру о необходимости проведения платежа через систему ”Расчет“
          </strong>
          (<strong>ЕРИП).</strong>
        </p>
        <p>
          <strong>Вы также можете оплатить </strong>
          <strong>заказ</strong>
          <strong>по коду услуги в ЕРИП:</strong>
        </p>
        <ul>
          <li>
            Пункт
            <strong>“Система “Расчет” (ЕРИП)</strong>
          </li>
          <li>
            <strong>Оплата в ЕРИП по коду услуги</strong>
          </li>
          <li>
            <strong>Вводите код 4671861</strong>
          </li>
        </ul>
        <h4>
          <strong>Банковской карточкой через интернет</strong>
        </h4>
        <p>
          {" "}
          После нажатия кнопки «Купить» вы перейдете на специальную защищенную
          платежную страницу процессинговой системы{" "}
          <a rel="noreferrer noopener" href="http://bepaid.by/" target="_blank">
            <strong>bePaid</strong>
          </a>
        </p>
        <p>
          На платежной странице будет указан номер заказа и сумма платежа. Для
          оплаты вам необходимо ввести свои карточные данные и подтвердить
          платеж, нажав кнопку «Оплатить».
        </p>
        <p>
          Если ваша карта поддерживает технологию 3-D Secure, системой ваш будет
          предложено пройти стандартную одноминутную процедуру проверки
          владельца карты на странице вашего банка (банка, который выдал вашу
          карту).
        </p>
        <p>
          После оплаты наш менеджер свяжется с вами для уточнения деталей по
          доставке.
        </p>
        <p>
          {" "}
          Обращаем ваше внимание, что после проведения платежа на указанный вами
          электронный адрес придет&nbsp;подтверждение&nbsp;оплаты. Просим вас
          сохранять данные оплат.
        </p>
        <p>
          {" "}
          Мы&nbsp;принимаем&nbsp;платежи по следующим банковским картам: Visa,
          Visa Electron, MasterCard, Maestro, Белкарт.
        </p>
        <figure className="wp-block-image">
          <img
            src="http://www.allsport.test:8080/wp-content/uploads/2019/07/image.png"
            alt=""
            className="wp-image-372"
          />
        </figure>
        <p>
          {" "}
          Платежи по банковским картам осуществляются через систему электронных
          платежей{" "}
          <a rel="noreferrer noopener" href="http://bepaid.by/" target="_blank">
            <strong>bePaid</strong>
          </a>{" "}
          .&nbsp;Платежная страница системы bePaid отвечает всем требованиям
          безопасности передачи данных (PCI DSS Level 1). Все конфиденциальные
          данные хранятся в зашифрованном виде и максимально устойчивы к взлому.
        </p>
        <p>
          Обращаем ваше внимание, что после проведения платежа на указанный вами
          электронный адрес придет подтверждение оплаты. Просим вас сохранять
          данные оплат.
        </p>
        <p>
          Возврат денежных средств осуществляется на карту, с которой ранее была
          произведена оплата. Срок поступления денежных средств на карту от 3 до
          30 дней с момента осуществления возврата Продавцом.
        </p>
        <p>
          {" "}
          Условия возврата описаны здесь:{" "}
          <em>
            <a href="/shipping/#conditions_cashback">Читать условия возврата</a>
          </em>
        </p>
        <h3>Условия доставки</h3>
        <p>
          Клубные карты изготавливаются в течении пяти рабочих дней с момента их
          оплаты. Доставка Клубных карт может осуществляться следующими
          способами:
        </p>
        <ul>
          <li>
            Самовывоз со следующего адреса: {data.site.siteMetadata.postAddress}.
            Предварительно требуется позвонить, в здании пропускная система.
          </li>
          <li>
            Доставка курьером по городу Минску на адрес, указанный в разделе
            "Личный кабинет". Стоимость доставки - 6 BYN. Оплата доставки
            производится наличными денежными средствами курьеру, непосредственно
            в момент доставки
          </li>
        </ul>
        <h3>Оплата Клубной карты взамен утерянной</h3>
        <ul>
          <li>
            В случае утери Пользователем предварительно оплаченной Клубной
            карты, свяжитесь с компанией ОЛЛСПОРТС по указанным{" "}
            <a href="/contact/">контактам</a>
          </li>
          <li>ОЛЛСПОРТС аннулирует текущую карту и выпустит новую.</li>
          <li>Срок выпуска новой карты - до 5 рабочих дней.</li>
          <li>Стоимость новой карты - 5 BYN.</li>
          <li>Условия оплаты выше</li>
          <li>Условия доставки выше</li>
        </ul>
        <h3 id="conditions_cashback">Условия возврата</h3>
        <p>
          Возврат для Клубной карты предусмотрен в случае с несогласием с
          изменениями в Публичной оферте, согласно пункту 13.5 текущей{" "}
          <a href="/img/public_oferta_11062019.pdf">Публичной оферты</a>. В
          других случаях возврат не предусмотрен.
        </p>
        <p>
          <em>
            «Если товар был оплачен банковской картой через сайт, то возврат
            осуществляется на карту, с которой была произведена оплата. Срок
            поступления денежных средств на карту -{" "}
          </em>{" "}
          <em>от 3 до 30 дней с момента осуществления возврата Продавцом»</em>
        </p>
        <h3> Условия доставки</h3>
        <p>
          В случае, если изготавливаются в течении пяти рабочих дней с момента
          их оплаты. Доставка Клубных карт может осуществляться следующими
          способами:
        </p>
        <ul>
          <li>
            Самовывоз со следующего адреса: {data.site.siteMetadata.postAddress}.
            Предварительно требуется позвонить, в здании пропускная система.
          </li>
          <li>
            Доставка курьером по городу Минску на адрес, указанный в разделе
            "Личный кабинет". Стоимость доставки - 6 BYN. Оплата доставки
            производится наличными денежными средствами курьеру, непосредственно
            в момент доставки
          </li>
        </ul>
      </div>
    )
  }
  if (block === "instruction_190401") {
    return (
      <div>
        <h3>Инструкция по подключению залов</h3>
        <h2>
          1. Звонок в зал - мониторинг информации о наличии телефона на
          ресепшене и согласовании времени подключения.
        </h2>
        <h2>
          2. При наличии в зале телефона - мы устанавливаем только приложение.
        </h2>
        <h2>Настройка телефона при первом включении</h2>
        <ul>
          <li>Настроить "как новое устройство"</li>
          <li> Вход в аккаунт Google - пропустить </li>
          <li>
            <strong>Отключить</strong> сервисы Google:{" "}
            <strong>
              использование геолокации, точное местоположение, помощь улучшения
              клавиатуры Android
            </strong>
          </li>
          <li>
            Задать <strong>имя - Allsports</strong>
          </li>
          <li>"Защитите телефон" - выбираем "не сейчас"</li>
          <li>"Что еще можно сделать" - выбираем "настроить позже".</li>
        </ul>
        <h2>Настройка приложений в телефоне</h2>
        <ul>
          <li>
            Настройки - <strong>аудио профили - бесшумный</strong>
          </li>
          <li>
            <strong>
              Приложения - удаление/отключение всех возможных, кроме "КАМЕРА",
              "Chrome".{" "}
            </strong>
            (Количество установленных приложений после очистки{" "}
            <strong>должно быть 36</strong>)
          </li>
          <li>С домашнего экрана удалить все приложения.</li>
        </ul>
        <h2>Настройка мобильной связи</h2>
        <ul>
          <li>
            Настройки - передача данных -
            <strong>мобильные данные - включить</strong>
          </li>
          <li>
            <strong>Экономия трафика - включить</strong> и вернуться по стрелке
            назад
          </li>
          <li>
            Выбираем вверху справа три вертикальных точки -{" "}
            <strong>мобильные сети - выключить интернет-роуминг</strong>
          </li>
          <li>
            <strong>Точка доступа APN</strong> -добавить новую:{" "}
            <strong>имя</strong> <strong>Allsports, APN m2m30.velcom.by</strong>
          </li>
          <li>
            Заходим в "передача мобильных данных" и через настройку переходим в
            <strong> платежный цикл</strong>.
          </li>
          <li>
            Установить <strong>лимит трафика - включаем</strong>.
          </li>
          <li>
            Устанавливаем{" "}
            <strong>
              ограничения по трафику: Предупреждения =10МБ, Лимит трафика =
              256МБ
            </strong>
          </li>
        </ul>
        <h2>Установка приложения на телефон</h2>
        <ul>
          <li>
            Через браузер Chrome открываем{" "}
            <a href="https://supplier.allsports.by/supplier/login">
              https://supplier.allsports.by/supplier/login
            </a>
          </li>
          <li>
            Предложение улучшить клавиатуру в Chrome , выполнить вход и прочее -
            игнорируем или отклоняем
          </li>
          <li>
            Выносим приложение на главный экран: справа адресной строки выбираем
            вертикальное троеточие - далее выбираем "добавить на главный экран"
          </li>
          <li>
            С главного экрана запускаем приложение под тестовой учетной записью.
            Для это используем{" "}
            <strong>e-mail - test@allsports.by, пароль 123456</strong>.{" "}
            <strong>Пароль-не запоминать, доступ к камере - разрешить.</strong>
          </li>
          <li>
            Проводим тест работы с картой <strong>test</strong>
          </li>
        </ul>
        <h2>Подготовка к подключению</h2>
        <ul>
          <li>
            Акт приема-передачи в безвозмездное пользование - 2 экземпляра. Один
            для нас, второй для зала.{" "}
            <strong>
              Заполняется : номер и дата договора, данные фирмы, серийный номер
              телефона, вручную дописывается телефонный номер(!), реквизиты
            </strong>
            . Если у одного юридического лица -{" "}
            <strong>несколько адресов</strong>, в акте обязательно пометить{" "}
            <strong>на котором адресе закреплен аппарат.</strong>
          </li>
          <li>
            {" "}
            <a href="https://journal.allsports.by/suppliers/list">
              https://journal.allsports.by/suppliers/list
            </a>{" "}
            в карточке поставщика добавить{" "}
            <strong>Users. Имя - reception, email </strong>задается произвольно
            по названию зала, <strong>пароль - рандомно 8 знаков</strong>.
            Данные логина и пароля - записываются{" "}
            <strong>на карточку для предоставления администратору зала.</strong>
          </li>
          <li>
            <strong>
              Через редактирование данных поставщика (Update) меняется состояние
              на "Онлайн"
            </strong>
          </li>
        </ul>
        <h2>Подключение в зале и действия после</h2>
        <ul>
          <li>
            При включении телефона(при наличии WiFi),{" "}
            <strong>СРАЗУ подключаемся к их WiFi</strong>
          </li>
          <li>
            Отдаем им карту с их логином и паролем и вводим их данные.{" "}
            <strong>Пароль- сохранить, доступ к камере - разрешить</strong>.
          </li>
          <li>
            Объясняем как работать (по инструкции) и даем администратору
            самостоятельно принять карту test. После сканирования -{" "}
            <strong>выбрать "отклонить"</strong>.
          </li>
          <li>
            Подписываем акты. При отсутствии возможности по месту получить
            подписанные акты - <strong>контролируем их получение</strong>.
          </li>
          <li>
            <strong>
              По возвращению на офис, в карту партнера (update) вносим
              телефонный номер и серийный номер в соответствующие поля.
            </strong>
          </li>
        </ul>
        <h2>
          Далее информация Только для нескольких поставщиков с одним ресепшн
        </h2>
        <h3>Установка 2 приложения на телефон, где уже есть один поставщик</h3>
        <ul>
          <li>
            Через браузер Chrome открываем{" "}
            <a href="https://gymreception.allsports.by/supplier/login">
              https://gymreception.allsports.by/supplier/login
            </a>
          </li>
          <li>
            Выносим приложение на главный экран: справа адресной строки выбираем
            вертикальное троеточие - далее выбираем "добавить на главный экран"
          </li>
          <li>
            С главного экрана запускаем приложение под тестовой учетной записью.
            Для это используем{" "}
            <strong>e-mail - test@allsports.by, пароль 123456</strong>.{" "}
            <strong>Пароль-не запоминать, доступ к камере - разрешить.</strong>
          </li>
          <li>
            Проводим тест работы с картой <strong>test</strong>
          </li>
        </ul>
        <h3>Подготовка к подключению 2 поставщика</h3>
        <ul>
          <li>
            {" "}
            <a href="https://journal.allsports.by/suppliers/list">
              https://journal.allsports.by/suppliers/list
            </a>{" "}
            в карточке 2 поставщика добавить{" "}
            <strong>Users. Имя - reception, email </strong>задается произвольно
            по названию зала, <strong>пароль - рандомно 8 знаков</strong>.
            Данные логина и пароля - записываются{" "}
            <strong>
              на карточку для предоставления администратору зала. На лицевой
              стороне напишите название 2 поставщика.
            </strong>
          </li>
          <li>
            <strong>
              Через редактирование данных поставщика (Update) меняется состояние
              на "Онлайн"
            </strong>
          </li>
        </ul>
        <h2>Подключение 2 поставщика в зале и действия после</h2>
        <ul>
          <li>
            Отдаем им карту с их логином и паролем и вводим их данные.{" "}
            <strong>Пароль- сохранить, доступ к камере - разрешить</strong>.
          </li>
          <li>
            Объясняем как работать (по инструкции) и даем администратору
            самостоятельно принять карту test. После сканирования -{" "}
            <strong>выбрать "отклонить"</strong>.
          </li>
          <li>
            Подписываем акты. При отсутствии возможности по месту получить
            подписанные акты - <strong>контролируем их получение</strong>.
          </li>
          <li>
            <strong>
              По возвращению на офис, в карту 2 партнера (update) вносим
              телефонный номер и серийный номер в соответствующие поля.
              Указываем в поле "серийное устройство", на кого из поставщиков
              оформлен Акт для телефона.
            </strong>
          </li>
        </ul>
      </div>
    )
  }
  if (block === "faq_partner") {
    return (
      <div>
        <h1>Инструкция для партнеров</h1>
        <h2>Установка программы. </h2>

        <p>
          Для установки рабочего приложения для партнеров Allsports необходимо:
        </p>

        <ul>
          <li>
            Через Google Chrome перейти по ссылке{" "}
            <a href="https://supplier.allsports.by/supplier/login">
              https://supplier.allsports.by/supplier/login
            </a>{" "}
            , на главном экране появится форма для входа, в которую необходимо
            внести индивидуальный{" "}
            <strong>логин и пароль, предоставленный менеджером </strong>компании
            ООО "Оллспортс";
          </li>
        </ul>

        <ul>
          <li>Подтвердить сохранение пароля и разрешить доступ к камере;</li>
        </ul>

        <ul>
          <li>
            Для удобства пользования - следует выбрать в дополнительных
            настройках <strong>"добавить на главный экран"</strong>. После
            этого, на домашнем экране телефона появится приложение{" "}
            <strong>"Supplier Panel"</strong> и повторить ввод логина и пароля.
          </li>
        </ul>

        <p>
          <strong>
            В случае телефона iPhone - вход через Siri и приложение добавляется
            в Закладки
          </strong>
        </p>

        <h2>Как принять посетителя с приложением Allsports?</h2>

        <GymSticker language="by" />

        <h2>Как принять посетителя с картой Allsports?</h2>

        <p>
          Обладатель мультиспортивной карты Allsports имеет право доступа в
          клубы-партнеры <strong>1 раз в день.</strong> Если лимит уже исчерпан,
          после сканирования карты, приложение Вас об этом уведомит сообщениями{" "}
          <strong>"НЕТ ДОСТУПА" </strong>и{" "}
          <strong>"дневной лимит достигнут</strong>"
        </p>

        <p>
          <strong>Карта Allsports является именной</strong> и позволяет
          пользоваться только тому, чьи данные указаны на карте.
        </p>

        <ul>
          <li>
            Запуск программы автоматически открывает панель сканирования карты
            (QR-code на обратной стороне).
          </li>
        </ul>

        <ul>
          <li>
            При удачном сканировании открывается таблица{" "}
            <strong>"данные Держателя карты"</strong> и возможность выбора
            отклонить-подтвердить
          </li>
        </ul>

        <ul>
          <li>
            Администратор{" "}
            <strong>
              обязан сверить данные карты с удостоверением личности посетителя
            </strong>{" "}
            (паспорт, водительское удостоверение, вид на жительство). В случае
            несовпадения данных - администратор имеет право изъять карту и
            должен внести данные карты (в таблице "данные держателя карты, поле
            находится внизу)
          </li>
        </ul>

        <ul>
          <li>
            <strong>Подтвердить</strong> - означает совпадение данных Держателя
            карты и начало оказания услуги. <strong>Отклонить</strong> - данные
            карты не совпали или клиент отказался от посещения до начала
            предоставления услуги. В случае, если Ваш зал предоставляет
            несколько видов услуг или требуется доплата в кассу зала, появятся
            выпадающие окна для выбора услуг.{" "}
            <strong>
              Завершение регистрации посетителя заканчивается открытием нового
              окна сканирования (камеры)
            </strong>
          </li>
        </ul>

        <h2>Навигация</h2>

        <ul>
          <li>
            <strong>Новая регистрация</strong>. Открывает приложение в режиме
            сканирования новой карты.
          </li>
        </ul>

        <ul>
          <li>
            <strong>Посещения</strong>. Во вкладке посещения отображаются все
            сканирования карт Allsports, с указанием даты и времени, а также
            указания предоставляемых услуг и их количества. Также есть
            возможность выбрать фильтром желаемый месяц.
          </li>
        </ul>

        <ul>
          <li>
            <strong>О нас.</strong> Во вкладке находятся телефонные контакты
            руководства и ссылка на инструкцию пользователя.
          </li>
        </ul>

        <ul>
          <li>
            <strong>О вас.</strong> Во вкладке находится информация о Вашем
            клубе, которые мы предоставляем Держателям карты Allsports. Также
            там находится перечень услуг и условия, которые предоставляет Ваш
            клуб для компании Allsports (т.е. какие услуги могут быть оказаны
            посетителю с картой Allsports)
          </li>
        </ul>

        <h2>Приятной работы!</h2>
      </div>
    )
  }
  if (block === "confidence_policy") {
    return (
      <div>
        <h2>Кто мы</h2>

        <p>Наш адрес сайта: {location.host}</p>

        <h2>Какие персональные данные мы собираем и с какой целью</h2>

        <h3>Комментарии</h3>

        <p>
          Если посетитель оставляет комментарий на сайте, мы собираем данные
          указанные в форме комментария, а также IP адрес посетителя и данные
          user-agent браузера с целью определения спама.
        </p>

        <p>
          Анонимизированная строка создаваемая из вашего адреса email ("хеш")
          может предоставляться сервису Gravatar, чтобы определить используете
          ли вы его. Политика конфиденциальности Gravatar доступна здесь:
          https://automattic.com/privacy/ . После одобрения комментария ваше
          изображение профиля будет видимым публично в контексте вашего
          комментария.
        </p>

        <h3>Медиафайлы</h3>

        <p>
          Если вы зарегистрированный пользователь и загружаете фотографии на
          сайт, вам возможно следует избегать загрузки изображений с метаданными
          EXIF, так как они могут содержать данные вашего месторасположения по
          GPS. Посетители могут извлечь эту информацию скачав изображения с
          сайта.
        </p>

        <h3>Формы контактов</h3>

        <h3>Куки</h3>

        <p>
          Если вы оставляете комментарий на нашем сайте, вы можете включить
          сохранение вашего имени, адреса email и вебсайта в куки. Это делается
          для вашего удобства, чтобы не заполнять данные снова при повторном
          комментировании. Эти куки хранятся в течение одного года.
        </p>

        <p>
          Если у вас есть учетная запись на сайте и вы войдете в неё, мы
          установим временный куки для определения поддержки куки вашим
          браузером, куки не содержит никакой личной информации и удаляется при
          закрытии вашего браузера.
        </p>

        <p>
          При входе в учетную запись мы также устанавливаем несколько куки с
          данными входа и настройками экрана. Куки входа хранятся в течение двух
          дней, куки с настройками экрана - год. Если вы выберете возможность
          "Запомнить меня", данные о входе будут сохраняться в течение двух
          недель. При выходе из учетной записи куки входа будут удалены.
        </p>

        <p>
          При редактировании или публикации статьи в браузере будет сохранен
          дополнительный куки, он не содержит персональных данных и содержит
          только ID записи отредактированной вами, истекает через 1 день.
        </p>

        <h3>Встраиваемое содержимое других вебсайтов</h3>

        <p>
          Статьи на этом сайте могут включать встраиваемое содержимое (например
          видео, изображения, статьи и др.), подобное содержимое ведет себя так
          же, как если бы посетитель зашел на другой сайт.
        </p>

        <p>
          Эти сайты могут собирать данные о вас, использовать куки, внедрять
          дополнительное отслеживание третьей стороной и следить за вашим
          взаимодействием с внедренным содержимым, включая отслеживание
          взаимодействия, если у вас есть учетная запись и вы авторизовались на
          том сайте.
        </p>

        <h3>Веб-аналитика</h3>

        <h2>С кем мы делимся вашими данными</h2>

        <h2>Как долго мы храним ваши данные</h2>

        <p>
          Если вы оставляете комментарий, то сам комментарий и его метаданные
          сохраняются неопределенно долго. Это делается для того, чтобы
          определять и одобрять последующие комментарии автоматически, вместо
          помещения их в очередь на одобрение.
        </p>

        <p>
          Для пользователей с регистрацией на нашем сайте мы храним ту личную
          информацию, которую они указывают в своем профиле. Все пользователи
          могут видеть, редактировать или удалить свою информацию из профиля в
          любое время (кроме имени пользователя). Администрация вебсайта также
          может видеть и изменять эту информацию.
        </p>

        <h2>Какие у вас права на ваши данные</h2>

        <p>
          При наличии учетной записи на сайте или если вы оставляли комментарии,
          то вы можете запросить файл экспорта персональных данных, которые мы
          сохранили о вас, включая предоставленные вами данные. Вы также можете
          запросить удаление этих данных, это не включает данные, которые мы
          обязаны хранить в административных целях, по закону или целях
          безопасности.
        </p>

        <h2>Куда мы отправляем ваши данные</h2>

        <p>
          Комментарии пользователей могут проверяться автоматическим сервисом
          определения спама.
        </p>

        <h2>Ваша контактная информация</h2>

        <h2>Дополнительная информация</h2>

        <h3>Как мы защищаем ваши данные</h3>

        <h3>Какие принимаются процедуры против взлома данных</h3>

        <h3>От каких третьих сторон мы получаем данные</h3>

        <h3>
          Какие автоматические решения принимаются на основе данных
          пользователей
        </h3>

        <h3>Требования к раскрытию отраслевых нормативных требований</h3>
      </div>
    )
  }
  if (block === "holder_app_policy") {
    return (
      <div>
        <h2>Клиенское приложение: Кто мы</h2>

        <p>Наш адрес сайта: https://www.allsports.by</p>

        <h2>Какие персональные данные мы собираем и с какой целью</h2>

        <h3>Данные при входе</h3>

        <p>
          Если посетитель входит в приложение, мы собираем IP адрес посетителя с
          целью определения спама.
        </p>

        <p>
          Введенный при входе номер телефона должен, вместе с Фамилией, Именем и
          типом подписки, предоставляться нам по договору с работодателем через
          его представителя.
        </p>

        <p>
          На номер телефона вам отправляется информация путем SMS, когда мы
          включили вашу подписку. Также, за несколько дней (1-5 рабочих дней) мы
          оповещаем вас о дате начала подписки путем SMS.
        </p>

        <h3>Видеопоток камеры для сканирования QR кода</h3>

        <p>
          Все данные находятся и обрабатываются только на телефоне. Видеопоток
          направляется напрямую в Xcamera (специальный протокол для обработки
          изображений) для вызова алгоритма нахождения qr кода. Мы не получаем и
          не обрабатываем эти данные.
        </p>

        <h3>Данные геолокации</h3>

        <p>
          Данные геолокации в обоих сценариях передаются на сервер с целью
          сохранения высокой скорости работы приложения.
        </p>

        <p>
          1 сценарий: геолокация может быть использована для поиска ближайших
          поставщиков. Поиск происходит в радиусе 50 метров. Данные с телефона
          пользователя нигде не хранятся, а используются для сравнения с данными
          в базе поставщиков AllSports и выдачи результатов.
        </p>

        <p>
          2 сценарий: геолокация используется для аналога идентификации в местах
          с отсутствием персонала, способного провести идентификацию посетителя,
          либо в местах с нарушениями правил идентификации как вспомогательное
          средство (альтернатива немедленному отключению данного заведения из
          сети AllSports). Мы не храним присланные координаты, но хранит
          информацию, какие визиты проходили по геолокации и какой телефон
          отправлял нам запросы на получение услуг. В случае подозрительной
          активности: запрос услуги далеко от расположения поставщика, частота и
          схема посещений заведений по одной подписке, которая не соответствует
          поведению одного человека, но соответствует группе людей,
          подтверждение нарушения правил со стороны поставщика, сговор с
          поставщиком, - подписка будет заблокирована, компании может быть
          отказано в предоставлении услуг, на поставщика могут накладываться
          издержки по договорам.
        </p>
        <p>
          По правилам AllSports идентификация посетителей обязательна,
          нарушившие данное правило заведения должны быть оштрафованы и
          отключены. Для предотвращения отключения заведений в случае с
          проблемами с персоналом (неотлаженные процессы), включается
          обязательная геолокация для получения услуги. Для телефонов с запретом
          геолокации данные заведения становятся недоступны.
          <b>
            Пользуясь приложением, вы даете согласие на предоставление
            геолокации с целью получения услуг.
          </b>
        </p>

        <h3>Настройки</h3>

        <p>
          Настройки приложения содержат персональные данные, предоставленные
          ранее работодателем, и служат только для корректной работы приложения.
        </p>

        <h2>Как долго мы храним ваши данные</h2>

        <p>
          Если вы регистрируете визит, то сам визит и его метаданные сохраняются
          неопределенно долго. Это делается для того, чтобы определять и
          одобрять последующие визиты автоматически, вместо помещения их в
          очередь на одобрение.
        </p>

        <p>
          Попытки сканирования карт и запросы на визиты в терминалогии AllSports
          являются "неуспешными визитами". На них распространяются те же
          правила, что и на визиты.
        </p>

        <h2>Какие у вас права на ваши данные</h2>

        <p>
          При наличии учетной записи в приложении, вы можете просмотреть ваши
          персональные данные, которые мы сохранили о вас. Вы также можете
          запросить удаление этих данных, тем самым вы подтверждаете согласие,
          что вы не будете пользоваться услугами Allsports без возможности
          компенсации. Мы сохраняем за собой право сохранять те данные, которые
          позволяют нам предотвращать нарушения договоров с клиентами или
          поставщиками, включая те данные, которые мы обязаны хранить в
          административных целях, по закону или целях безопасности.
        </p>

        <h2>Куда мы отправляем ваши данные</h2>

        <p>
          Визиты в клубы могут проверяться автоматическим сервисом определения
          спама.
        </p>

        <h2>Дополнительная информация</h2>

        <h3>Как мы защищаем ваши данные</h3>

        <p>
          Ваши данные не доступны клубам через наше приложение вне времени
          вашего посещения. Все ваши персональные данные передаются вашему
          android/iphone приложению в процессе работы.
        </p>

        <h3>Какие принимаются процедуры против взлома данных</h3>

        <p>
          Ценные данные можно загрузить через API приложения в рамках вашей
          уникальной сессии, которая создается при вводе номера телефона и
          авторизационного кода. Приложение имеет ограниченное количество
          активаций, в случае истечения количества активаций требуется покупка
          новой подписки.
        </p>

        <h3>От каких третьих сторон мы получаем данные</h3>

        <p>
          Мы получаем данные от клубов в случае нарушений договоров либо правил
          клуба. Данные обрабатываются и могут быть основанием для отказа в
          продлении подписки.
        </p>

        <h3>
          Какие автоматические решения принимаются на основе данных
          пользователей.
        </h3>

        <p>Автоматические решения не принимаются.</p>
      </div>
    )
  }
  if (block === "android_policy") {
    return (
      <div>
        <h2>Партнерское приложение: Кто мы</h2>

        <p>Наш адрес сайта: {location.host}</p>

        <h2>Какие персональные данные мы собираем и с какой целью</h2>

        <h3>Данные при входе</h3>

        <p>
          Если посетитель входит в приложение, мы собираем IP адрес посетителя с
          целью определения спама.
        </p>

        <h3>Настройки</h3>

        <p>
          Настройки приложения не содержат ваши персональные данные и служат
          только для корректной работы приложения.
        </p>

        <h2>Как долго мы храним ваши данные</h2>

        <p>
          Если вы регистрируете визит, то сам визит и его метаданные сохраняются
          неопределенно долго. Это делается для того, чтобы определять и
          одобрять последующие визиты автоматически, вместо помещения их в
          очередь на одобрение.
        </p>

        <p>
          Для пользователей с регистрацией на нашем сайте мы храним ту личную
          информацию, которую они указывают в профиле клуба. Все пользователи
          могут видеть и запрашивать изменения на свою информацию из профиля в
          любое время (включая имя пользователя). Администрация также может
          видеть и изменять эту информацию. Администрация может отказаться от
          изменения информации, если данное изменение создает преимущество
          одного клуба перед другим вне пунктов договора.
        </p>

        <h2>Какие у вас права на ваши данные</h2>

        <p>
          При наличии учетной записи в приложении, вы можете просмотреть
          публичные данные клуба, которые мы сохранили о вас, включая
          предоставленные вами данные. Вы также можете запросить удаление этих
          данных, это не включает данные, которые мы обязаны хранить в
          административных целях, по закону или целях безопасности.
        </p>

        <h2>Куда мы отправляем ваши данные</h2>

        <p>
          Визиты в клубы могут проверяться автоматическим сервисом определения
          спама.
        </p>

        <h2>Дополнительная информация</h2>

        <h3>Как мы защищаем ваши данные</h3>

        <p>
          Все публичные данные о клубе предоставляются в свободном доступе на
          нашем сайте и нашим клиентам. Все персональные данные клуба в android
          приложении отсутствуют.
        </p>

        <h3>Какие принимаются процедуры против взлома данных</h3>

        <p>
          Ценные данные нельзя загрузить через API приложения. Доступны только
          публичные данные о клубе, данные об услугах согласно договору с
          конкретным клубом и визиты держателей карт AllSportS в данный клуб.
        </p>

        <h3>От каких третьих сторон мы получаем данные</h3>

        <p>
          Мы получаем данные от держателей клуба о качестве работ различных
          клубов. Данные обрабатываются и предоставляются в виде общих
          рекомендаций по увеличению потока посетителей в клубы.
        </p>

        <h3>
          Какие автоматические решения принимаются на основе данных
          пользователей.
        </h3>

        <p>Автоматические решения не принимаются.</p>
      </div>
    )
  }
  return (
    <div className="faq">
      <h3>Ваши вопросы и ответы</h3>

      <div></div>
    </div>
  )
}

export default Content
